import { ReactComponent as ShareArrowIcon } from '@brand/icons/share-arrow.svg'
import clsx from 'clsx'
import styles from './icon-ctas.module.css'
import type { MouseEventHandler } from 'react'
import { Button } from '../button/button'
import { useFeatureVariable } from '@rentpath/ab-testing-react'

interface ShareButtonProps {
  'data-tag_selection'?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  listingCardRedesignVersion?: number
  isRentSrpRedesign?: boolean
  className?: string
  isAGListingCard?: boolean
}

export function ShareButton({
  onClick,
  listingCardRedesignVersion,
  isRentSrpRedesign,
  className,
  isAGListingCard,
  ...props
}: ShareButtonProps) {
  const isBookmarkIconEnabled = useFeatureVariable(
    ['bookmark_icon', 'bookmarkIconEnabled'],
    false
  )
  const isBookmarkLabelEnabled = useFeatureVariable(
    ['bookmark_icon', 'bookmarkLabelEnabled'],
    false
  )

  const isRedesignV2 = listingCardRedesignVersion === 2
  const classNameDerived = isRentSrpRedesign
    ? styles.withRentRedesignStyles
    : isRedesignV2
    ? styles.shareButtonRedesignV2
    : clsx(styles.iconCtaStyle, styles.default, {
        [styles.bookmarkButtonStyle]:
          isBookmarkIconEnabled &&
          isBookmarkLabelEnabled &&
          (listingCardRedesignVersion === 2 ||
            listingCardRedesignVersion == undefined),
        [styles.bookmarkLabelButtonRedesignV1]:
          isBookmarkIconEnabled &&
          isBookmarkLabelEnabled &&
          listingCardRedesignVersion === 1,
        [styles.shareButtonAG]: isAGListingCard,
      })

  const Component = isRedesignV2 || isRentSrpRedesign ? Button : 'button'

  return (
    <Component
      data-tag_item="share_button"
      data-tag_selection={props['data-tag_selection']}
      data-tid="share"
      className={clsx(className, classNameDerived)}
      aria-label="share"
      onClick={onClick}
      variant={
        isRedesignV2
          ? 'agSecondaryRedesign'
          : isRentSrpRedesign
          ? 'rentTertiaryRedesign'
          : undefined
      }
      rounded={isRedesignV2 ? true : undefined}
    >
      <ShareArrowIcon aria-hidden />
    </Component>
  )
}
